.table-striped tbody tr:nth-of-type(odd) {
    background-color: #303144;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: #282a3a;
}
.tableStocks {
    line-height: 13px;
    font-size: 13px;
}

.tableStocks th {
    border: none;
}

.stockModalBlock .modal-dialog {
    max-width: 1100px;
 }

 .stockModalBody {
    max-height: 50vh;
    overflow: auto;
 }
 .centerContent {
     text-align: center;
 }

 .tableStocks tr {
    font-size: 14px;
    font-family: Consolas,monaco,monospace;
}

.stockModalBody td {
    border: none;
}

.report-name {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    color: #fff;
    font-family: "Tw Cen MT";
    padding: 0px;
    margin: 50px 0 30px;
}

.report-size {
    height: 394px !important;
}

.report-main {
    padding: 25px 0 25px;
    border-radius: 5px;
    max-width: 1070px;
    margin: 0 auto;
    position: relative;
}

.report-main .InovuaReactDataGrid--theme-default-dark .InovuaReactDataGrid__header {
    background: #282d3a;
}

.report-main .InovuaReactDataGrid--theme-default-dark .InovuaReactDataGrid__row--even,.InovuaReactDataGrid--theme-default-dark .InovuaReactDataGrid__row--no-zebra {
    background: #282d3a
}

.report-main .InovuaReactDataGrid--theme-default-dark .InovuaReactDataGrid__row--odd {
    background: #313943 !important;
}

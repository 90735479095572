.button {
  height: 55px;
  cursor: pointer;
  border: 0px;
  display: block;
  min-width: 180px;
  text-align: center;
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  padding: 0px 30px;
  background: rgb(245, 131, 18);
  background: -moz-linear-gradient(left, rgba(245, 131, 18, 1) 0%, rgba(245, 181, 27, 1) 99%);
  background: -webkit-linear-gradient(left, rgba(245, 131, 18, 1) 0%, rgba(245, 181, 27, 1) 99%);
  background: linear-gradient(to right, rgba(245, 131, 18, 1) 0%, rgba(245, 181, 27, 1) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#f5b51b',GradientType=1 );
  line-height: 55px;
  border-radius: 3px;
  transition: 0.3s;
  white-space: nowrap;

  &:disabled {
    background: grey !important;
    cursor: not-allowed;
  }

  &:hover {
    color: #ffffff;
    background: rgb(245, 181, 27);
    background: -moz-linear-gradient(left, rgba(245, 181, 27, 1) 0%, rgba(245, 132, 18, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(245, 181, 27, 1) 0%, rgba(245, 132, 18, 1) 100%);
    background: linear-gradient(to right, rgba(245, 181, 27, 1) 0%, rgba(245, 132, 18, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5b51b', endColorstr='#f58412',GradientType=1 );
  }
}

.small {
  height: 38px;
  line-height: 38px;
  min-width: 130px;
}

.right {
  float: right;
}

.menu {
  text-align: center;
  padding-bottom: 25px;
}

.link {
  cursor: pointer;
  padding: 0 15px;
  background: #44bcf6;
  transition: 0.3s;
  color: #fff;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  margin: 0 5px;

  &:hover {
    background: transparent;
    color: #fff;
  }
}

.active {
  background: rgb(245, 131, 18) !important;
  border: none;
}

.header {
  cursor: pointer;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

.link {
  color: rgba(169, 169, 169, 0.48);
}

.wrapper {
  cursor: grab;
}

/*------------------------------------------------Tw Cen MT-----------------------------------------------*/
@font-face {
    font-family: 'Tw Cen MT Condensed Extra';
    src: url('../fonts/TwCenMT-CondensedExtraBold.woff2') format('woff2'),
        url('../fonts/TwCenMT-CondensedExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Tw Cen MT Condensed';
    src: url('../fonts/TwCenMT-Condensed.woff2') format('woff2'),
        url('../fonts/TwCenMT-Condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tw Cen MT';
    src: url('../fonts/TwCenMT-Regular.woff2') format('woff2'),
        url('../fonts/TwCenMT-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tw Cen MT';
    src: url('../fonts/TwCenMT-Bold.woff2') format('woff2'),
        url('../fonts/TwCenMT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tw Cen MT';
    src: url('../fonts/TwCenMT-BoldItalic.woff2') format('woff2'),
        url('../fonts/TwCenMT-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Tw Cen MT Condensed';
    src: url('../fonts/TwCenMT-CondensedBold.woff2') format('woff2'),
        url('../fonts/TwCenMT-CondensedBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tw Cen MT';
    src: url('../fonts/TwCenMT-Italic.woff2') format('woff2'),
        url('../fonts/TwCenMT-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
/*------------------------------------------------Tw Cen MT-----------------------------------------------*/




/*------------------------------------------------Venera700-----------------------------------------------*/
@font-face {
    font-family: 'Venera';
    src: url('../fonts/Venera700.woff2') format('woff2'),
        url('../fonts/Venera700.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/*------------------------------------------------Venera700-----------------------------------------------*/


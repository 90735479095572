.editableField:focus {
  border: 1px solid #aaa;
}
.editableField {
  margin-left: 5px;
  display: inline-block;
}

.edit-active-class {
  border: 1px solid #887e7e;
  padding: 0px 10px;
}

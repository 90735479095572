@import url("./fonts.css");
@import url("./bootstrap.css");
@import url("./jquery.fancybox.min.css");
@import url("./aos.css");
@import url("./fontawesome.css");

/* Global Box-sizing
------------------------------------*/
/* {transition: all 0.5s ease-in;}*/

body {
  background: #131827;
  font-weight: 400;
  font-family: "Tw Cen MT";
  font-style: normal;
  font-size: 21px;
  line-height: 42px;
  color: #ffffff;
  padding: 0;
  margin: 0px;
  position: relative;
}

body {
  background: #131827 url(../images/background.jpg) no-repeat center center fixed;
  background-size: 100% 100%;
}
body:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

p {
  font-weight: 400;
  font-family: "Tw Cen MT";
  font-style: normal;
  font-size: 21px;
  line-height: 42px;
  color: #ffffff;
  padding: 0;
  margin: 0 0 30px 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Venera";
}
a,
span,
i {
  display: inline-block;
}
a,
a:hover,
a:visited {
  text-decoration: none;
}
a:focus,
span:focus,
button:focus,
li,
div,
input,
* {
  outline: none;
}
img,
a img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  list-style: none;
  border: none;
  box-sizing: border-box;
}
a {
  color: #44bcf6;
}
.clearfix:before,
.gform_wrapper:before,
ul:before,
.clearfix:after,
.gform_wrapper:after,
ul:after {
  content: "";
  display: table;
}
.clearfix:after,
.gform_wrapper:after,
ul:after {
  clear: both;
}
.clearfix,
.gform_wrapper,
ul {
  *zoom: 1;
}
.borderBtn {
  padding: 0px 50px;
  margin: 0;
  font-size: 17px;
  line-height: 50px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 5px solid #161436;
  display: inline-block;
  font-weight: 500;
  margin-top: 35px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#443caa+1,272364+100 */
  background: rgb(68, 60, 170); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(68, 60, 170, 1) 1%,
    rgba(39, 35, 100, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(68, 60, 170, 1) 1%,
    rgba(39, 35, 100, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(68, 60, 170, 1) 1%,
    rgba(39, 35, 100, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#443caa', endColorstr='#272364',GradientType=0 ); /* IE6-9 */
}
.borderBtn:hover {
  color: #fff;
  background: #1e1a58;
}

.defaultBtn {
  color: #fff;
  padding: 0px 25px;
  border-radius: 25px;
  transition: 0.3s;
  line-height: 45px;
  background-color: #44bcf6;
}
.defaultBtn:hover {
  background-color: #27a5e2;
  color: #ffffff;
}

h2 {
  font-size: 44px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding: 0px;
  margin: 0px;
  position: relative;
}
h2 span {
  color: #2bb3ea;
  display: block;
}
h2.left {
  text-align: left;
}

h4 {
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px;
  margin: 0px;
  position: relative;
}
h4 span {
  color: #2bb3ea;
  display: block;
}
h4.left {
  text-align: left;
}

h3 {
  font-size: 19px;
}

::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls {
  display: none !important;
}

#myVideo {
  position: fixed;
  right: 0;
  left: 0;
  top: 0px;
  bottom: 0;
  z-index: -1;
}
#myVideo video {
  width: 100%;
}

.headSec {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  z-index: 5;
}
.headSec .header {
  margin: 0 100px;
  padding: 30px 0px 20px;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
  transition: 0.3s;
}
header.header .mainMenu {
}
header.header .mainMenu ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
header.header .mainMenu ul li {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
header.header .mainMenu ul li + li {
  margin-left: 15px;
}
header.header .mainMenu ul li a {
  color: #fff;
  padding: 0px 20px;
  border-radius: 25px;
  transition: 0.3s;
}
header.header .mainMenu ul li:hover a,
header.header .mainMenu ul li.active a {
  background-color: #44bcf6;
}
header.header .mainMenu ul li:last-child a,
header.header .mainMenu ul li:nth-last-child(2) a {
  background-color: #44bcf6;
}
header.header .mainMenu ul li:last-child:hover a,
header.header .mainMenu ul li:nth-last-child(2):hover a {
  background-color: transparent;
}
.header.fixed {
  background: rgba(21, 24, 41, 0.9);
}

.footerSec {
  margin-top: 80px; /*background-color: #131827;*/
}
.footerSec .footer {
  margin: 0 150px;
  padding: 35px 0px;
  border-top: 1px solid rgba(235, 235, 235, 0.5);
}
.footerSec .footer p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.footerSec .footer i {
  color: #ff0000;
}
.footerSec .footer .termLinks {
  text-align: right;
}
.footerSec .footer a {
  color: #fff;
  transition: 0.3s;
}
.footerSec .footer a:hover {
  color: #3fbae4;
}

.bodySec {
  padding: 180px 0 0px;
}
.homeTxt {
  text-align: center;
}
.homeTxt h6 {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  font-family: "Tw Cen MT";
  padding: 0px;
  margin: 10px 0 30px;
}
.homeGraph {
  margin-bottom: 15px;
  background: #282d3a;
  padding: 25px 0 25px;
  border-radius: 5px;
  width: 1070px;
  margin: 0 auto;
  position: relative;
}
.homeGraphNone {
  background: transparent;
}
.homeGraphFlex {
  display: flex;
  justify-content: space-between;
}

.homeGraphTable {
  margin-bottom: 15px;
  padding: 0px 0 25px;
  border-radius: 5px;
  max-width: 1070px;
  margin: 0 auto;
  position: relative;
}

.nextPredictions h6 {
  margin: 50px 0 30px;
}
.nextPredictions .field {
  max-width: 550px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
}
.nextPredictions .field input[type="text"] {
  width: 380px;
  min-height: 58px;
  font-size: 19px;
  font-weight: 400;
  color: #05043c;
  padding: 0 20px;
  margin: 0px;
  border-radius: 5px 0 0 5px;
  font-family: "Calibri";
}
.nextPredictions .field input[type="submit"] {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  cursor: pointer;
  width: 145px;
  height: 58px;
  border-radius: 0 5px 5px 0;
  float: right;
  background: rgb(245, 131, 18);
  background: -moz-linear-gradient(
    left,
    rgba(245, 131, 18, 1) 0%,
    rgba(245, 181, 27, 1) 99%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(245, 131, 18, 1) 0%,
    rgba(245, 181, 27, 1) 99%
  );
  background: linear-gradient(
    to right,
    rgba(245, 131, 18, 1) 0%,
    rgba(245, 181, 27, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#f5b51b',GradientType=1 );
}

.shadowImg {
  text-align: right;
  margin-top: 50px;
  position: relative;
  z-index: 2;
}
.shadowImg img {
  box-shadow: -25px 25px 0px #282d3a;
  position: relative;
}
.aboutSec h2 {
  text-align: left;
  margin-bottom: 40px;
}
.faqSec h2 {
  text-align: left;
  margin-bottom: 40px;
  font-family: 'Calibri';
}
.aboutTxtBg {
  background: #34a6e5 url(../images/about-body-logo.png) right top no-repeat;
  min-height: 535px;
  padding: 60px 50px 0 130px;
  margin-left: -100px;
  position: relative;
  z-index: 0;
}
.aboutTxtBg h3 {
  font-size: 26px;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
.aboutTxtBg h3 span {
  font-size: 21px;
  color: #0c4361;
  display: block;
}
.aboutTxtBg p {
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  margin: 0px;
  line-height: 42px;
}
.aboutTxtBg a {
  color: #0c4361;
  text-decoration: underline;
  transition: 0.3s;
}
.aboutTxtBg a:hover {
  color: #ffffff;
  text-decoration: none;
}

.whatWeDoSec .shadowImg {
  margin-top: 0;
}
.whatWeDoSec .shadowImg img {
  box-shadow: -40px 40px 0px #282d3a;
}
/*.whatWeDoTxt { padding-left: 25px; }*/
.whatWeDoTxt h2 {
  font-size: 40px;
  text-align: left;
  margin-bottom: 25px;
}
/*.whatWeDoTxt p { font-size: 21px; font-weight: 400; color: #fff; padding: 0px; margin: 0px; line-height: 42px; }*/
.whatWeDoTxt p {
  line-height: 35px;
}
.whatWeDoTxt p + p {
  margin-top: 20px;
}
.whatWeDoTxt ul {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 50px;
}
.whatWeDoTxt ul li {
  position: relative;
  padding: 0 0 0 25px;
  line-height: 35px;
}
.whatWeDoTxt ul li:before {
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0px;
  top: 14px;
  content: "";
  background-color: #44bcf6;
  border-radius: 50%;
}

.scroll {
  height: 550px;
  overflow-y: scroll;
  padding-right: 30px;
}
.scroll::-webkit-scrollbar {
  width: 5px;
}
.scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #44bcf6;
}

@keyframes autofill {
  100% {
    font-size: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    font-size: inherit;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:first-line,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:first-line,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:first-line,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-appearance: none;
  -webkit-text-fill-color: #fff !important;
  -webkit-animation: autofill 0s forwards;

  animation: autofill 0s forwards;

  border-radius: 0px;
  outline: none;
  padding-right: 0px;
  margin: 0px;
  text-indent: 0px;
  font-size: 18px;
  font-family: inherit;
}

.storySec .shadowImg {
  margin: 0px;
}

.storySec .whatWeDoTxt {
  padding-right: 30px;
}

.contactSec h2 {
  text-align: left;
}
.contactSec .contactInfo {
  background-color: #282d3a;
  padding: 25px;
  min-height: 150px;
  border-radius: 5px;
  margin-bottom: 32px;
}
.contactSec .contactInfo h6 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.contactSec .contactInfo ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}
.contactSec .contactInfo ul li {
  font-size: 22px;
  color: #fff;
  padding: 0px;
  margin: 0px;
  line-height: 30px;
}
.contactSec .contactInfo ul li span {
  color: #42bbef;
  margin-right: 10px;
}
.contactSec .contactInfo ul li a {
  color: #fff;
  transition: 0.3s;
}
.contactSec .contactInfo ul li a:hover {
  color: #42bbef;
}

.contactSec .contactInfo .locationMap {
}

/* Mobile Menu Animated Icon
------------------------------------*/
#nav-icon4 {
  display: none;
  width: 30px;
  height: 20px;
  position: absolute;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 100;
  right: 20px;
  top: 10px;
}
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  cursor: pointer;
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4 span:nth-child(2) {
  top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4 span:nth-child(3) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}
#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}
#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 22px;
  left: 8px;
}

/* AnimationForm
------------------------------------*/
.AnimationForm {
  margin-top: 50px;
}
.AnimationForm .field {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 35px;
  float: left;
  width: calc(50% - 50px);
}
.AnimationForm .field.full {
  width: calc(100% - 50px);
}
.AnimationForm .field:before {
}
.AnimationForm .field:nth-child(2n) {
  margin-left: 50px;
}
.AnimationForm .field fieldset {
  position: absolute;
  top: 10px;
  left: 30px;
  color: #fff;
  font-size: 21px;
  font-weight: 400;
  transform: translate3d(0, 0, 0);
  transition: all 0.5s ease-in-out;
  z-index: 1;
}
.AnimationForm .field fieldset span {
  color: #ff0000;
}
.AnimationForm .field input[type="password"],
.AnimationForm .field input,
.AnimationForm .field select,
.AnimationForm .field textarea {
  z-index: 111;
  position: relative;
  background: transparent;
  width: 100%;
  padding: 15px 30px;
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  border-bottom: 3px solid #3f424c;
}
.AnimationForm .field select {
  padding: 23px 30px;
}
.AnimationForm .field select option {
  color: #000;
}
.AnimationForm .field input:focus {
  outline: 0;
}
.AnimationForm .field fieldset.active {
  z-index: 999;
  top: -20px;
}
.AnimationForm .field.phone fieldset {
  left: 120px;
}
.AnimationForm .field.textarea textarea {
  height: 140px;
  resize: none;
}
.AnimationForm .field.submit {
  background-color: transparent;
}
.AnimationForm .field:nth-last-child(2),
.AnimationForm .field:nth-last-child(1) {
  width: calc(100% - 50px);
  margin-left: 0;
}
/*.AnimationForm .field [type="submit"] {*/
/*  cursor: pointer;*/
/*  border: 0px;*/
/*  display: block;*/
/*  width: 180px;*/
/*  text-align: center;*/
/*  font-size: 21px;*/
/*  font-weight: 400;*/
/*  color: #fff;*/
/*  padding: 0px 30px;*/
/*  float: right;*/
/*  font-family: "Tw Cen MT";*/
/*  background: rgb(245, 131, 18);*/
/*  background: -moz-linear-gradient(*/
/*    left,*/
/*    rgba(245, 131, 18, 1) 0%,*/
/*    rgba(245, 181, 27, 1) 99%*/
/*  );*/
/*  background: -webkit-linear-gradient(*/
/*    left,*/
/*    rgba(245, 131, 18, 1) 0%,*/
/*    rgba(245, 181, 27, 1) 99%*/
/*  );*/
/*  background: linear-gradient(*/
/*    to right,*/
/*    rgba(245, 131, 18, 1) 0%,*/
/*    rgba(245, 181, 27, 1) 99%*/
/*  );*/
/*  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#f5b51b',GradientType=1 );*/
/*  line-height: 55px;*/
/*  border-radius: 3px;*/
/*}*/

/* Graph Styling
------------------------------------*/
#chartContainer {
  height: 450px;
  width: 100%;
  max-width: 1020px;
  margin: 0px auto;
}
.canvasjs-chart-credit {
  font-size: 0 !important;
}
.canvasjs-chart-tooltip {
  display: none !important;
}

/* Pricing Page Styling
------------------------------------*/

.priceBox {
  border: 2px solid #3caadf;
  background-color: #131827;
  min-height: 215px;
  transition: 0.3s;
  margin-top: 50px;
  padding: 40px 15px;
}
.priceBox:hover {
  box-shadow: 0px 7px 112.32px 4.68px rgba(57, 169, 210, 0.5);
}
.priceBox .priceBox--header {
  border-bottom: 2px solid #3f424c;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.priceBox .priceBox--header h2 {
  font-size: 35px;
  text-align: left;
  line-height: 25px;
}
.priceBox .priceBox--header p {
  font-size: 18px;
  color: #fff;
  margin: 0;
}
.priceBox .priceBox--amount {
}

.priceBox .priceBox--amount .priceBox-del {
  text-decoration-line: line-through;
  text-decoration-color: red;
  text-decoration-thickness: 4px;
  margin-right: 3px;
}
.priceBox .priceBox--amount .priceBox-off {
  margin: 5px 0 0 0;
  position: relative;
}
.priceBox .priceBox--amount .priceBox-off:before {
  content: attr(data-off) "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 100px;
  font-size: 16px;
  letter-spacing: normal;
  font-weight: bold;
  color: #f58312;
}
.priceBox .priceBox--amount ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.priceBox .priceBox--amount ul li {
  /*width: 33%;*/
}
.priceBox .priceBox--amount ul li h6 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Tw Cen MT";
  line-height: 32px;
  letter-spacing: -2px;
}
.priceBox .priceBox--amount ul li p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  line-height: 32px;
  margin: 0;
}
.priceBox .priceBox--amount ul li:nth-child(2) {
  width: 30px;
  text-align: center;
}
.priceBox .priceBox--amount ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #46cff7;
  border-radius: 50%;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  font-style: normal;
  line-height: 30px;
}
.priceBox .priceBox--content {
  margin-top: 30px;
}
.priceBox .priceBox--content ul {
  margin-bottom: 20px;
}
.priceBox .priceBox--content ul li {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 0 0 0 30px;
  margin: 0 0 5px 0;
  position: relative;
  line-height: 30px;
}
.priceBox .priceBox--content ul li:before {
  background: url(../images/check-with-circle.svg) left top no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 5px;
  content: "";
}
.priceBox .priceBox--footer {
  display: flex;
}
.priceBox .priceBox--footer a {
  flex: 1;
  padding: 0 5px;
  text-align: center;
}
.priceBox .priceBox--footer a {
  display: inline-block;
  line-height: 54px;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#44bcf7+0,3fbae3+100 */
  background: #44bcf7; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #44bcf7 0%,
    #3fbae3 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #44bcf7 0%,
    #3fbae3 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #44bcf7 0%,
    #3fbae3 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#44bcf7', endColorstr='#3fbae3',GradientType=1 ); /* IE6-9 */
}
.priceBox .priceBox--footer a + a {
  margin-left: 15px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f58312+1,e7b42a+100 */
  background: #f58312; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #f58312 1%,
    #e7b42a 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #f58312 1%,
    #e7b42a 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #f58312 1%,
    #e7b42a 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#e7b42a',GradientType=1 ); /* IE6-9 */
}
.entPriceBg {
  background-color: #3fbae4;
  margin-top: 50px;
  padding: 40px 20px;
  transition: 0.3s;
}
.entPriceBg:hover {
  box-shadow: 0px 7px 80px 4.68px rgba(57, 169, 210, 0.5);
}
.entPriceBg h2 {
  font-size: 36px;
  padding-bottom: 5px;
  border-bottom: 2px solid #89d5fa;
  text-align: left;
  margin-bottom: 30px;
}
.entPriceBg h2 span {
  color: #fff;
}
.entPriceBg ul {
}
.entPriceBg ul li {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 0 0 0 30px;
  margin: 0 0 0px 0;
  position: relative;
}
.entPriceBg ul li:before {
  background: url(../images/circle-check.svg) left top no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 6px;
  content: "";
}
.moreInfo {
  margin: 45px 0 0 0;
}
.moreInfo p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  margin: 0px;
}
.moreInfo p a {
  color: #46cff7;
}
.moreInfo p a:hover {
  color: #ffffff;
}

.entMoreInfo {
  margin: 20px 0px 4px 10px;
}
.entMoreInfo p {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding: 0px;
  margin: 0px;
}
.entMoreInfo p a {
  color: #a6ffff;
}
.entMoreInfo p a:hover {
  color: #ffffff;
}

/* SignUp Page Styling
------------------------------------*/
.signUpTabs {
}
.signUpTabs h2 {
  margin-top: 60px;
  text-align: left;
}
.signUpTabs ul {
  background-color: #414d72;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  border-radius: 30px;
  margin: 60px 0;
}
.signUpTabs ul li {
  padding: 0px 60px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 30px;
  margin: 0 5px;
}
.signUpTabs ul li:first-child {
  margin-left: 0px;
}
.signUpTabs ul li:last-child {
  margin-right: 0px;
}
.signUpTabs ul li.active,
.signUpTabs ul li:hover {
  background-color: #44bcf6;
}
.AnimationForm .signUpTabs .field,
.AnimationForm .signUpTabs .field:nth-last-child(1),
.AnimationForm .signUpTabs .field:nth-last-child(2) {
  width: 100%;
}
.AnimationForm .signUpTabs .field {
  margin-bottom: 72px;
}

.AnimationForm .field .input-error { border: 0; border-bottom: 2px solid #ff0000 !important; }
.AnimationForm .signUpTabs .field [type="button"].btn-previous {
  margin-right: 20px;
  background: #44bcf6;
}
.AnimationForm .signUpTabs .field.leftSubmit [type="button"] {
  float: left;
}
.AnimationForm .signUpTabs .field.leftSubmit {
  margin-bottom: 0;
}

.thanksMsg {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanksMsg h6 {
  font-size: 22px;
  color: #fff;
}

/* SignIn Page Styling
------------------------------------*/
.signUpTabs.signIn h2 {
  margin-bottom: 120px;
}
.AnimationForm .signUpTabs.signIn .field [type="submit"] {
  float: left;
}
.AnimationForm .signUpTabs.signIn .field.submit {
  margin-bottom: 0px;
}

/* Fotgot Page Styling
------------------------------------*/
.fotgotInfo {
  margin-top: 30px;
}
.fotgotInfo ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  background: transparent;
  width: auto;
}
.fotgotInfo ul li {
  font-size: 21px;
  font-weight: 400;
  color: #44bcf6;
  margin-top: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  text-transform: inherit;
}
.fotgotInfo ul li + li {
  margin-left: 50px;
}
.fotgotInfo ul li a {
  color: #41c3e9;
  padding: 0;
  transition: 0.3s;
  text-decoration: underline;
}
.fotgotInfo ul li:hover {
  background-color: transparent;
}
.fotgotInfo ul li:hover a {
  color: #ffffff;
  text-decoration: none;
}

.fileUpload {
  background-color: #111325;
  height: 165px;
  border-radius: 3px;
}
.fileUpload ul {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: transparent;
  width: 100%;
}
.fileUpload ul li {
  padding: 0;
}
.fileUpload ul li img {
  border-radius: 3px;
}
.fileUpload ul li:last-child {
  width: 52%;
}
.fileUpload ul li:hover {
  background: transparent;
}

.AnimationForm .field input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileBox {
  border: 1px dashed #235875;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 140px;
}
.fileBox i {
}
.fileBox h6 {
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.fileBox p {
  font-size: 14px;
  font-weight: 400;
  color: #acacb0;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0px;
  line-height: 28px;
}
.fileBox > div {
  text-align: center;
}
.editProfile .field.imgSec {
  margin-top: 90px;
}

/*-----CUSTOM CHECKBOX-------*/
.customCheck {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  color: #fff;
  margin-top: 30px;
}
.customCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 11px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #44bcf6;
}
.customCheck:hover input ~ .checkmark {
}
.customCheck input:checked ~ .checkmark {
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.customCheck input:checked ~ .checkmark:after {
  display: block;
}
.customCheck .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.homeInvest {
  margin: 80px 0 0;
}
.homeInvest .shadowImg img {
  box-shadow: 25px 25px 0px #282d3a;
}
.homeInvest h4 {
  margin-top: 80px;
  margin-bottom: 20px;
}
.homeInvest p {
  margin-bottom: 20px;
}

/* Stock Page Styling
------------------------------------*/
.stockSec {
  padding: 180px 0 100px;
}
.stockSec h2 {
  text-align: left;
  margin-bottom: 24px;
}
.stockBg {
  background-color: rgba(40, 45, 58, 0.6);
  text-align: center;
  min-height: 180px;
  border-radius: 10px;
  padding: 20px 20px 0px 20px;
  margin-bottom: 25px;
  margin: auto 20px;
}
.stockBg p {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px 0 0px 0px;
  margin: 0px;
  text-align: center;
}
.stockBg h6 {
  position: relative;
  top: -25px;
  color: #44bcf6;
  font-size: 18px;
  font-family: "Tw Cen MT";
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.ui-state-default.box {
  background: transparent;
  border: 0;
}

.stockBg div {
  height: 180px;
}

.stockBg .highcharts-legend, .stockBg .highcharts-credits {
  display: none;
}
.ui-state-default.box g.highcharts-exporting-group {
  display: none;
}
g.highcharts-exporting-group g.highcharts-contextbutton {
  display: none;
}

g.highcharts-exporting-group g.highcharts-future-button > text {
  fill: #cccccc !important;
}
g.highcharts-exporting-group g.highcharts-future-selected > text {
  fill: #ffffff !important;
}
g.highcharts-exporting-group g.highcharts-future-button:hover > text {
  fill: #ffffff !important;
}
g.highcharts-exporting-group g.highcharts-future-button > rect {
  fill: #505053 !important;
}
g.highcharts-exporting-group g.highcharts-future-selected > rect {
  fill: #000003 !important;
}
g.highcharts-exporting-group g.highcharts-future-button:hover > rect {
  fill: #707073 !important;
}

.ui-state-default.box a {
  position: absolute;
  right: 32px;
  top: 10px;
  font-size: 20px;
  width: 15px;
  text-align: center;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  transition: 0.3s;
  opacity: 0.8;
}
/*.ui-state-default.box a i { color: #fff; font-weight: 300;}*/
.ui-state-default.box a:hover {
  opacity: 1;
}
.ui-state-default.box a:hover {
  opacity: 1;
}
/* .ui-state-default.box a:before, .ui-state-default.box a:after { position: absolute; left: 7px; content: ' '; height: 8px; width: 2px; background-color: #bfbfbf; top: 3px; opacity: 0.5; transition: 0.3s;} */
.ui-state-default.box a:before {
  transform: rotate(45deg);
}
.ui-state-default.box a:after {
  transform: rotate(-45deg);
}
.ui-state-default.box a:hover:before,
.ui-state-default.box a:hover:after {
  opacity: 1;
}

/* Dashboard Page Styling
------------------------------------*/
.dashboardSec {
  padding: 180px 0 0px;
}
.dashboardSec h2 {
  text-align: left;
  margin-bottom: 50px;
}
.dashboardSec h2 span {
  display: inline-block;
}
.dashboardSec h2 a {
  display: inline-block;
  font-size: 19px;
  color: #f59b16;
  text-transform: capitalize;
  font-family: "Tw Cen MT";
}
.dashboardSec h2 a:hover {
  color: #2bb3ea;
}

.dashStokSec {
  margin-bottom: 50px;
}

.dashOrdSec {
}
.orderTable {
}
.orderTable table {
  font-size: 18px;
}
.orderTable table thead {
  position: relative;
  /*z-index: 5;*/
}
.orderTable table thead th {
  border: 0;
  background-color: rgba(40, 45, 58, 0.8);
  font-size: 19px;
  font-weight: 400;
  color: #46cff7;
  padding: 20px 10px;
  margin: 0px;
  font-family: "Tw Cen MT";
  position: sticky;
  top: 0px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.orderTable table tbody td {
  border-top: 1px solid rgba(255, 255, 255, 0.17);
  line-height: 55px;
}
.orderTable table td,
.orderTable table th {
  padding: 20px 10px;
}

.dashBoardSlide .slick-next,
.dashBoardSlide .slick-prev {
  font-size: 0;
  width: 45px;
  background: transparent;
  border: 2px solid #26708b;
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
  position: absolute;
  top: -80px;
  transition: 0.3s;
}
.dashBoardSlide .slick-next {
  right: 20px;
}
.dashBoardSlide .slick-next:before,
.dashBoardSlide .slick-prev:before {
  content: "\f105";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  font-size: 30px;
  margin: auto;
  color: #fff;
  font-family: "Fontawesome";
}
.dashBoardSlide .slick-prev:before {
  content: "\f104";
}
.dashBoardSlide .slick-prev {
  right: 80px;
}

.dashBoardSlide .slick-next:hover,
.dashBoardSlide .slick-prev:hover {
  border-color: #44bcf6;
  background-color: #44bcf6;
}

.dashEdit {
  background-color: rgba(40, 45, 58, 0.8);
  margin-bottom: 100px;
  padding: 25px 20px;
  border-radius: 5px;
}

.dashEdit .profInfo {
}
.dashEdit .profInfo ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.dashEdit .profInfo ul li {
  font-size: 21px;
  font-weight: 400;
  color: #ffffff;
  padding: 10px 0 0px 0;
  margin: 0 40px 0 0px;
}
.dashEdit .profInfo ul li a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}
.dashEdit .profInfo ul li a:hover {
  color: #42bbef;
}
.dashEdit .profInfo ul li span {
  font-size: 24px;
  color: #42bbef;
}

.dashEdit a.edit {
  color: #f59b16;
  transition: 0.3s;
  padding-right: 15px;
  padding-top: 15px;
}
.dashEdit a.edit:hover {
  color: #ffffff;
}
.dashEdit a.edit i {
  margin-right: 7px;
}

.dashHead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 16px;
  margin-bottom: 32px;
}
.dashHead h2 {
  padding: 0px;
  margin: 0px;
  display: inline-block;
}
.dashHead ul {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashHead ul li {
}
.dashHead ul li + li {
  margin-left: 15px;
}
.dashHead ul li a {
  padding: 0px 25px;
  background: #44bcf6;
  transition: 0.3s;
  color: #fff;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.dashHead ul li a:hover {
  background: transparent;
}

.pageMain {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pageMain ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageMain ul li {
  margin: 5px;
  line-height: 30px;
}
.pageMain ul li a {
  border: 1px solid #44bcf6;
  padding: 0 5px;
  border-radius: 3px;
  min-width: 35px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #44bcf6;
  transition: 0.3s;
}
.pageMain ul li a:hover,
.pageMain ul li a.active {
  background-color: #2d99ce;
  border-color: #2d99ce;
  color: #ffffff;
}

/* Responsive
------------------------------------*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1270px;
  }
}
@media (max-width: 1620px) {
  header.header .mainMenu ul li + li {
    margin-left: 7px;
  }
  header.header .mainMenu ul li a {
    padding: 0px 15px;
  }
}

@media (max-width: 1435px) {
  .headSec .header {
    margin: 0 50px;
  }
  header.header .mainMenu ul li + li {
    margin-left: 7px;
  }
  header.header .mainMenu ul li a {
    padding: 0px 10px;
  }
}

@media (min-width: 1282px) and (max-width: 1366px) {
  header.header .mainMenu ul li {
    font-size: 15px;
  }
  header.header .mainMenu ul li + li {
    margin-left: 10px;
  }
  header.header .mainMenu ul li a {
    padding: 2px 12px;
  }
}

@media (min-width: 1170px) and (max-width: 1281px) {
  .headSec .header {
    margin: 0 30px;
  }

  header.header .mainMenu ul li + li {
    margin-left: 7px;
  }
  header.header .mainMenu ul li a {
    padding: 2px 8px;
  }
  header.header .mainMenu ul {
    justify-content: center;
  }
  .logo {
    text-align: center;
  }
}
@media (min-width: 1000px) and (max-width: 1169px) {
  h4 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  .logo {
    text-align: center;
  }
  .headSec .header {
    margin: 0 20px;
  }
  header.header .mainMenu ul {
    justify-content: center;
  }
  header.header .mainMenu ul li a {
    padding: 2px 18px;
  }
  header.header .mainMenu ul li {
    font-size: 13px;
  }
  header.header .mainMenu ul li + li {
    margin-left: 7px;
  }
  .footerSec .footer {
    margin: 0 20px;
  }

  .whatWeDoTxt h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .whatWeDoTxt p {
    font-size: 18px;
    line-height: 32px;
  }

  .aboutTxtBg {
    padding: 40px 40px 30px 100px;
  }

  .homeGraph {
    width: 100%;
  }
  .homeGraph:before {
    left: 0px;
  }

  .homeInvest h4 {
    margin-top: 0px;
  }
  .footerSec .footer p {
    margin: 0px;
  }

  .scroll {
    height: 450px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headSec .header {
    margin: 0 0px;
  }
  header.header .mainMenu ul {
    justify-content: center;
  }
  header.header .mainMenu ul li {
    font-size: 14px;
  }
  header.header .mainMenu ul li + li {
    margin-left: 5px;
  }
  header.header .mainMenu ul li a {
    padding: 2px 15px;
  }

  #nav-icon4 {
    display: block;
  }
  header.header .mainMenu {
    display: none;
  }

  header.header .mainMenu {
    display: none;
    position: absolute;
    left: 0;
    top: 17px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
  }
  header.header .mainMenu ul {
    flex-wrap: wrap;
  }
  header.header .mainMenu ul li {
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  header.header .mainMenu ul li:hover a,
  header.header .mainMenu ul li.active a,
  header.header .mainMenu ul li:last-child a,
  header.header .mainMenu ul li:nth-last-child(2) a {
    border-radius: 0;
    display: block;
  }
  header.header .mainMenu ul li + li {
    margin-left: 0px;
  }
  #nav-icon4 {
    display: block;
  }

  .footerSec .footer {
    padding: 15px 0px;
    margin: 0;
  }
  .copyTxt,
  .footerSec .footer .termLinks {
    text-align: center;
  }

  .whatWeDoTxt {
    padding-left: 0;
  }
  .whatWeDoSec .shadowImg img {
    box-shadow: -20px 20px 0px #282d3a;
  }
  .whatWeDoTxt h2 {
    font-size: 27px;
    margin-bottom: 15px;
  }
  .whatWeDoTxt p {
    font-size: 16px;
    line-height: 30px;
  }

  .aboutTxtBg h3 {
    margin: 0;
  }
  .aboutTxtBg {
    min-height: inherit;
    padding: 20px 20px 20px 100px;
  }

  .homeGraph {
    width: 100%;
  }
  .homeGraph:before {
    left: 0px;
    bottom: 22px;
  }

  .priceBox .priceBox--content a {
    font-size: 16px;
    padding: 0 8px;
  }
  .priceBox .priceBox--amount ul li:nth-child(2) {
    margin: 0 15px;
  }

  .AnimationForm .field fieldset {
    font-size: 16px;
  }

  .homeInvest h4 {
    margin-top: 20px;
  }
  .homeInvest p {
    line-height: 32px;
  }

  .footerSec .footer p {
    line-height: normal;
    margin: 0;
  }
}
@media (max-width: 767px) {
  h2,
  .whatWeDoTxt h2 {
    font-size: 22px;
    margin-bottom: 0px;
  }
  h4 {
    font-size: 18px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    line-height: 30px;
  }
  .defaultBtn {
    padding: 0px 15px;
    line-height: 35px;
    font-size: 17px;
  }
  .logo {
    max-width: 200px;
  }

  .headSec .header {
    margin: 0 0px;
  }
  header.header .mainMenu {
    display: none;
  }

  .bodySec {
    padding: 130px 0 0px;
  }
  .homeTxt h6 {
    font-size: 20px;
    margin: 10px 0;
  }

  .nextPredictions .field {
    max-width: 100%;
  }
  .nextPredictions .field input[type="text"] {
    width: 60%;
  }
  .nextPredictions .field input[type="submit"] {
    width: 110px;
  }

  .footerSec {
    margin-top: 20px;
  }
  .footerSec .footer {
    margin: 0 0px;
    padding: 10px 0px;
    text-align: center;
  }
  .footerSec .footer .termLinks {
    text-align: center;
  }

  .shadowImg {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .shadowImg img,
  .whatWeDoSec .shadowImg img {
    box-shadow: -10px 10px 0px #282d3a;
  }

  .whatWeDoTxt {
    padding-left: 0;
    padding-top: 30px;
  }
  .whatWeDoTxt p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .whatWeDoTxt p + p {
    margin-top: 25px;
  }
  .storySec .whatWeDoTxt {
    padding-right: 0px;
    padding-bottom: 50px;
  }

  .aboutSec h2 {
    margin-bottom: 0px;
  }
  .aboutTxtBg {
    min-height: inherit;
    padding: 20px;
    margin-left: 0px; /*z-index: -1;*/
  }
  .aboutTxtBg p {
    font-size: 18px;
    line-height: 30px;
  }

  .AnimationForm .field {
    width: calc(100% - 0px);
  }
  .AnimationForm .field:nth-child(2n) {
    margin-left: 0;
  }
  .AnimationForm .field:nth-last-child(2),
  .AnimationForm .field:nth-last-child(1) {
    width: calc(100% - 00px);
    margin-left: 0;
  }

  header.header .mainMenu {
    display: none;
    position: absolute;
    left: 0;
    top: 17px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.9);
  }
  header.header .mainMenu ul {
    flex-wrap: wrap;
  }
  header.header .mainMenu ul li {
    width: 100%;
    line-height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  header.header .mainMenu ul li:hover a,
  header.header .mainMenu ul li.active a,
  header.header .mainMenu ul li:last-child a,
  header.header .mainMenu ul li:nth-last-child(2) a {
    border-radius: 0;
    display: block;
  }
  header.header .mainMenu ul li + li {
    margin-left: 0px;
  }
  #nav-icon4 {
    display: block;
  }

  .homeInvest {
    margin: 20px 0 0;
  }
  .homeInvest h4 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .homeGraph {
    width: 100%;
  }
  .homeGraph:before {
    left: 0px;
    bottom: 22px;
  }

  .priceBox .priceBox--header {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .priceBox .priceBox--header h2 {
    font-size: 25px;
  }
  .priceBox .priceBox--content a {
    line-height: 45px;
    font-size: 16px;
    padding: 0 10px;
  }
  .priceBox .priceBox--content a + a {
    margin-left: 5px;
  }
  .priceBox .priceBox--content ul {
    margin-bottom: 10px;
  }
  .priceBox .priceBox--amount ul li:nth-child(2) {
    margin: 0 15px;
  }

  .entPriceBg h2 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .entPriceBg {
    margin-top: 30px;
    padding: 20px;
  }

  .signUpTabs h2 {
    margin-top: 0px;
  }
  .signUpTabs ul {
    border-radius: 0;
    margin: 10px 0 30px;
    flex-direction: column;
    width: 100%;
  }
  .signUpTabs ul li {
    border-radius: 0;
    width: 100%;
    padding: 5px 20px;
  }
  .AnimationForm .signUpTabs .field {
    margin-bottom: 35px;
  }
  .AnimationForm .field fieldset {
    font-size: 18px;
  }
  .AnimationForm .signUpTabs .field [type="submit"] {
    font-size: 18px;
    width: auto;
    padding: 0 35px;
    text-transform: uppercase;
    line-height: 40px;
  }
  .thanksMsg h6 {
    font-size: 20px;
    text-align: center;
    line-height: 40px;
  }

  .footerSec .footer p {
    margin: 0;
  }
}
@media (max-width: 340px) {
  .priceBox .priceBox--amount ul li p {
    font-size: 15px;
  }
  .priceBox {
    padding: 20px 10px;
  }
}

.orangeBtnByPage {
  font-family: "Tw Cen MT";
  background: rgb(245, 131, 18) !important;
  background: -moz-linear-gradient(
    left,
    rgba(245, 131, 18, 1) 0%,
    rgba(245, 181, 27, 1) 99%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(245, 131, 18, 1) 0%,
    rgba(245, 181, 27, 1) 99%
  );
  border: none;
  white-space: nowrap;
}

.orangeBtnByPage:disabled {
  background: gray !important;
  cursor: not-allowed;
}

.InovuaReactDataGrid--theme-default-dark .InovuaReactDataGrid__row--odd {
  background: #282d3a !important;
}

.singleStockChartTable{
  font-size: 20px !important;
}

.InovuaReactDataGrid.selectStockModal {
  color: white;
  max-height: 45vh;
}


.InovuaReactDataGrid__row--selected,
.InovuaReactDataGrid__row--selected .InovuaReactDataGrid__cell {
  background: transparent !important;
  color: white;
}

.InovuaReactDataGrid__row--selected:hover,
.InovuaReactDataGrid__row--selected:hover .InovuaReactDataGrid__cell {
  background: transparent !important;
  color: white;
}

/*.form-control.is-valid, .form-control.is-invalid {*/
/*  padding-right: calc(1.5em + 0.75rem);*/
/*  background-repeat: no-repeat;*/
/*  background-position: right calc(0.375em + 0.1875rem) center;*/
/*  background-size: calc(.75em + .375rem) calc(.75em + .375rem);*/
/*}*/

/*.form-control.is-valid, .was-validated .form-control:valid {*/
/*  border-color: #28a745;*/
/*  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");*/
/*}*/

/*.form-control.is-invalid, .was-validated .form-control:invalid {*/
/*  border-color: #dc3545;*/
/*  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");*/
/*}*/

.valid-feedback.feedback-icon,
.invalid-feedback.feedback-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 10px;
  right: 10px;
  margin-top: 0;
}
.valid-feedback.feedback-icon {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.invalid-feedback.feedback-icon {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
}

input#promo:-webkit-autofill,
input#promo:-webkit-autofill:hover,
input#promo:-webkit-autofill:first-line,
input#promo:-webkit-autofill:focus {
  height: 38px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

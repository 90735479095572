.lineHeading {
    text-decoration: underline;
}
.text-bold {
    font-weight: 650;
}
.text-italic {
    font-style: italic;
}

.romanLowerStyle li {
    list-style: lower-roman;
    margin-left: 0.5em;
    text-indent: 0em;
}
.marginParagraph {
    margin-left: 1em;
    text-indent: 0em;
}

.dashListStyle li {
    list-style: none !important;
}

.dashListStyle li:before {
    content: "-";
    padding-right: 5px;
  }

  .termsofuseclass p{ 
      line-height: 26px;
  }

  .privacyandPolicy p {
      line-height: 28px;
  }
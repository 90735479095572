.right {
  float: right;
}

.big {
  border: 16px solid rgba(245, 181, 27, 0.72);
  border-radius: 50%;
  border-top: 16px solid #46bcf624;
  border-bottom: 16px solid #46bcf624;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 10% auto;
  color: transparent;
}

.small {
  border: 7px solid #f5b3260f;
  border-top: 7px solid #f4ae28;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

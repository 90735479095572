.modal-content {
    background-color: #252630;
}

.modal-header .close { 
    color: #ffffff;
}


/*=============================
            LOADER
===============================*/
.loader {
    border: 16px solid rgba(245, 181, 27, 0.72);
    border-radius: 50%;
    border-top: 16px solid #46bcf624;
    border-bottom: 16px solid #46bcf624;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 10% auto;
    color: transparent;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .modal-header {
    border-bottom: 1px solid #887e7e
  }

  .modal-footer{
    border-top: 1px solid #887e7e
  }



  .smallLoader {
    border: 7px solid #f5b3260f;
    border-top: 7px solid #f4ae28;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .notfound {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: 47vh;
    text-align: center;
    margin-top: 5%;
  }
  
  .welcome{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: 47vh;
    text-align: center;
    margin-top: 5%;
  }

  .InovuaReactDataGrid {
    text-align: center;
  }
.left-label-profile {
  display: inline-block;
  color: #42bbef;
}

.right-label-profile {
  float: right;
  display: inline-block;
}

.active-edit-box {
  color: #ffffff;
}
.inactive-edit-box{
  color: #f59b16;
}
.inactive-edit-box:hover{
  color: #ffffff;
}

.saveButton {
    cursor: pointer;
    border: 0px;
    display: block;
    width: 100px;
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    padding: 0px 30px;
    float: left;
    font-family: 'Tw Cen MT';
    background: rgb(245,131,18);
    background: linear-gradient(to right, rgba(245,131,18,1) 0%,rgba(245,181,27,1) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#f5b51b',GradientType=1 );
    line-height: 55px;
    border-radius: 3px;
    transition: 0.3s;
}
.closeButton{
    cursor: pointer;
    border: 0px;
    display: block;
    width: 113px;
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #fff;
    padding: 0px 30px;
    float: right;
    font-family: 'Tw Cen MT';
    background: rgb(70, 188, 246);
    background: linear-gradient(to right, rgb(70, 188, 246) 0%,rgb(130, 175, 198) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f58312', endColorstr='#f5b51b',GradientType=1 );
    line-height: 55px;
    border-radius: 3px;
    transition: 0.3s;
}

.labeleditprofile{
  min-width: 135px;
  float: left;
}

.edit-button-profile {
  font-family: 'Tw Cen MT';
}